import { useQuery, useMutation, queryCache } from 'react-query'
import { useAxios } from 'packages/core'
import { CURRENT_PERSON_KEY } from './personHooks'

export const ALL_NOTIFICATIONS_KEY = 'ALL_NOTIFICATIONS_KEY'

export const DIGEST_EMAILS_NOTIFICATION_PREFERENCE_KEY =
    'DIGEST_EMAILS_NOTIFICATION_PREFERENCE_KEY'

export const RISK_REPORTS_NOTIFICATION_PREFERENCE_KEY = 'RISK_REPORTS_NOTIFICATION_PREFERENCE_KEY'

export const useAllNotifications = () => {
    const callApi = useAxios()

    return useQuery(ALL_NOTIFICATIONS_KEY, () =>
        callApi({
            method: 'GET',
            url:
                'api/v1/businessRequestNotification/notificationPersonDefaultPreference',
        }).then((data) => data.data),
    )
}

export const useUpdateGlobalNotificationSettings = () => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/v1/me/identities',
                data,
            }),
        {
            onSettled: () => {
                queryCache.invalidateQueries(CURRENT_PERSON_KEY)
            },
        },
    )
}

export const useUpdateNotificationStatus = () => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/v1/BusinessRequestNotification/edit',
                data,
            }),
        {
            onSettled: () => {
                queryCache.invalidateQueries(ALL_NOTIFICATIONS_KEY)
            },
        },
    )
}

export const useResetEventEmailsNotificationSettings = () => {
    const callApi = useAxios()
    return useMutation(
        () =>
            callApi({
                method: 'PATCH',
                url:
                    '/api/v1/BusinessRequestNotification/resetPersonNotificationPreferences',
            }),
        {
            onSuccess: () => {
                queryCache.invalidateQueries(ALL_NOTIFICATIONS_KEY)
            },
        },
    )
}

export const useDigestEmailsNotificationPreference = (type) => {
    const callApi = useAxios()

    return useQuery([DIGEST_EMAILS_NOTIFICATION_PREFERENCE_KEY, type], () =>
        callApi({
            method: 'GET',
            url:
                `/api/v1/BusinessRequestNotification/digestEmailsNotificationPreference/${type}`,
        }).then((data) => data.data),
        { enabled: Boolean(type) }
    )
}

export const useEditDigestEmailNotification = () => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url:
                    '/api/v1/BusinessRequestNotification/digestEmailsNotificationPreference/edit',
                data,
            }),
        {
            onSettled: () => {
                queryCache.invalidateQueries(
                    DIGEST_EMAILS_NOTIFICATION_PREFERENCE_KEY,
                )
            },
        },
    )
}

export const useResetDigestEmailsNotificationPreferences = () => {
    const callApi = useAxios()
    return useMutation(
        () =>
            callApi({
                method: 'PATCH',
                url:
                    '/api/v1/BusinessRequestNotification/resetDigestEmailsNotificationPreferences',
            }),
        {
            onSuccess: () => {
                queryCache.invalidateQueries(
                    DIGEST_EMAILS_NOTIFICATION_PREFERENCE_KEY,
                )
            },
        },
    )
}
