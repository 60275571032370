import { useQuery } from 'react-query'
import { useAxios } from '../packages/core'
export const WORKFLOWS_KEY = 'WORKFLOWS'

export const useWorkflows = () => {
    const callApi = useAxios()
    return useQuery([WORKFLOWS_KEY], () =>
        callApi({
            method: 'GET',
            url: `api/UIActions/?noun=MyIdentity&verb=SelfService`,
        }).then((data) => data.data),
    )
}
