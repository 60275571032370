import { useQuery, useMutation } from 'react-query'
import { useAxios } from 'packages/core'
import { transformOrgChart } from './utils'

export const CURRENT_PERSON_KEY = 'CURRENT_PERSON'

export const useCurrentPerson = () => {
    const callApi = useAxios()
    return useQuery(
        CURRENT_PERSON_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/v1/me',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useUpdatePersonLocale = () => {
    const callApi = useAxios()
    return useMutation(
        (locale) =>
            callApi({
                method: 'PATCH',
                url: '/api/v1/people/locale',
                data: locale,
            }),
        {
            onSuccess: () => {
                window.location.reload()
            },
        },
    )
}

export const useOrgChart = (personId) => {
    const callApi = useAxios()
    return useQuery([CURRENT_PERSON_KEY, 'ORG_CHART'], () =>
        callApi({
            method: 'GET',
            url: `/api/v1/me/orgchart/${personId}`,
        }).then((data) => transformOrgChart(data.data)),
    )
}

export const useTimeZones = () => {
    const callApi = useAxios()
    return useQuery(
        [CURRENT_PERSON_KEY,'TIME_ZONES'],
        () =>
            callApi({
                method: 'GET',
                url: '/api/v1/me/timezones',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
